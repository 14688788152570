import { createContext } from 'solid-js';
import { isServer } from 'solid-js/web';
import { createClient } from './create-client';
import type { Client } from '@urql/core';
import type { ParentProps } from 'solid-js';

export * from './__generated__';
export * from './__generated__/graphql';
export * from './fragments';
export * from './mutation-action';
export * from './query-action';
export * from './get-api-client';
export * from './create-client';
export * from './error';
export * from './cache-keys';

const ApiContext = createContext<Client>();

export function ApiClient(props: ParentProps & { client?: Client }) {
	const client = props.client ?? createClient({}, !isServer ? window.location.pathname : '/');

	return <ApiContext.Provider value={client}>{props.children}</ApiContext.Provider>;
}
