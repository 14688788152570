type Props = {
	id: string;
};

export function SkipToMain(props: Props) {
	return (
		<div class="pointer-events-none absolute inset-0 flex justify-center">
			<a
				class="pointer-events-auto fixed z-50 mx-auto flex -translate-y-16 rounded border border-brand bg-white px-4 py-2 font-semibold text-brand outline-none duration-200 focus-visible:translate-y-4 focus-visible:border-brand focus-visible:after:absolute focus-visible:after:inset-0 focus-visible:after:rounded-sm focus-visible:after:border focus-visible:after:border-brand active:scale-95 active:bg-brand-100 motion-safe:transition-transform"
				href={`#${props.id}`}
			>
				Skip to main content
			</a>
		</div>
	);
}
