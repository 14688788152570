import posthog from 'posthog-js';

export function createClient(
	key: string,
	userId?: string,
	featureFlags?: Record<string, string | boolean>,
	disableSessionRecording: boolean = false,
) {
	const ph = posthog.init(key, {
		api_host: 'https://app.posthog.com',
		bootstrap: {
			distinctID: userId,
			isIdentifiedID: !!userId,
			featureFlags,
		},
		persistence: 'localStorage',
		person_profiles: 'identified_only',
		disable_surveys: true,
		disable_session_recording: disableSessionRecording,
	})!;
	return ph;
}
