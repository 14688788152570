import { Button, Dialog, DialogTrigger, Form } from '@troon/ui';
import { createAsync } from '@solidjs/router';
import { For } from 'solid-js';
import { Title } from '@solidjs/meta';
import { gql, mutationAction, useMutation } from '../../graphql';
import { AddCard } from '../../partials/add-card';
import { cardBrandToComponent, cardBrandToString } from '../../modules/credit-cards';
import { cachedQuery } from '../../graphql/cached-get';
import type { RouteDefinition } from '@solidjs/router';

export default function PaymentMethods() {
	const data = createAsync(() => getPaymentMethods({}));
	const removeCardAction = useMutation(removeCard);

	return (
		<div>
			<Title>Payment methods | My account | Troon</Title>
			<h1 class="text-3xl font-semibold">Payment Methods</h1>

			<ul class="my-8">
				<For
					each={data()?.creditCards}
					fallback={<li class="text-center italic text-neutral-700">No payment methods available.</li>}
				>
					{(card) => {
						const CardIcon = cardBrandToComponent[card.brand];
						return (
							<li class="flex flex-row items-center gap-2 border-b border-neutral py-4 last:border-b-0">
								<div class="w-10">
									<CardIcon />
								</div>
								<div class="grow">
									{cardBrandToString[card.brand]} ending in {card.lastFour}
								</div>
								<Form action={removeCardAction} document={removeCardMutation} method="post">
									<input type="hidden" name="cardId" value={card.id} />
									<Button appearance="secondary" action="danger" type="submit">
										Remove
									</Button>
								</Form>
							</li>
						);
					}}
				</For>
			</ul>

			<DialogTrigger>
				<Button appearance="secondary">Add new payment method</Button>
				<Dialog header="Add payment method" headerLevel="h2" key="add-payment-method">
					{(handleClose) => (
						<AddCard
							onCancel={handleClose}
							onSuccess={async () => {
								handleClose();
							}}
						/>
					)}
				</Dialog>
			</DialogTrigger>
		</div>
	);
}

export const route = {
	preload: async () => {
		return getPaymentMethods({});
	},
} satisfies RouteDefinition;

const query = gql(`
query paymentMethods {
	creditCards {
		id
		lastFour
		brand
	}
}`);

const getPaymentMethods = cachedQuery(query);

const removeCardMutation = gql(`
mutation removeCreditCard($cardId: String!) {
	removeCreditCard(cardId: $cardId) {
		ok
	}
}`);

const removeCard = mutationAction(
	removeCardMutation,
	{
		revalidates: ['paymentMethods'],
	},
	{ additionalTypenames: ['CreditCard'] },
);
