import { shouldPolyfill as shouldPolyfillDateTime } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumber } from '@formatjs/intl-numberformat/should-polyfill';
import { setTag } from '@sentry/browser';

const locale = 'en';

export async function polyfill() {
	const dataPolyfills: Array<Promise<unknown>> = [];
	const fillDate = shouldPolyfillDateTime(locale);
	setTag('polyfill.date', fillDate);
	if (fillDate) {
		await import('@formatjs/intl-datetimeformat/polyfill-force');

		dataPolyfills.push(
			import('@formatjs/intl-datetimeformat/add-golden-tz'),
			import('@formatjs/intl-datetimeformat/locale-data/en'),
		);
	}

	const fillNumber = shouldPolyfillNumber();
	setTag('polyfill.number', fillNumber);
	if (fillNumber) {
		await import('@formatjs/intl-numberformat/polyfill-force');

		dataPolyfills.push(import('@formatjs/intl-numberformat/locale-data/en'));
	}

	const fills = await Promise.all(dataPolyfills);
	return fills;
}
