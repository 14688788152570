import { mount, StartClient } from '@solidjs/start/client';
import { AnalyticsProvider } from '@troon/analytics';
import { createClient } from '@troon/analytics/client';
import { getAccessibleName } from 'accname';
import {
	init as initSentry,
	breadcrumbsIntegration,
	browserTracingIntegration,
	browserProfilingIntegration,
} from '@sentry/browser';
import { ErrorBoundary } from './components/error-boundary';
import { ApiClient } from './graphql';
import { getConfigValue } from './modules/config';
import { polyfill } from './providers/polyfill';

const ua = window.navigator.userAgent.toLowerCase();
const isDisallowedClient = ua.includes('lighthouse') || ua.includes('bingbot/') || ua.includes('googlebot/');

// Polyfill for Object.hasOwn – we get hit by lots of old bots that need this
if (!Object.hasOwn) {
	Object.defineProperty(Object, 'hasOwn', {
		value: function (obj: Record<string, unknown>, prop: string) {
			return Object.prototype.hasOwnProperty.call(obj, prop);
		},
		configurable: true,
		enumerable: false,
		writable: true,
	});
}

const posthog =
	!isDisallowedClient && getConfigValue('POSTHOG_ENABLED')
		? createClient(
				getConfigValue('POSTHOG_WRITE_KEY'),
				window.__USERID__,
				window.__FLAGS__ ?? {},
				!getConfigValue('POSTHOG_ENABLE_SESSION_RECORDINGS', false),
			)
		: undefined;

if (!isDisallowedClient && getConfigValue('SENTRY_ENABLED')) {
	initSentry({
		dsn: getConfigValue('SENTRY_DSN'),
		environment: getConfigValue('ENVIRONMENT', 'development'),
		integrations: [
			breadcrumbsIntegration({
				history: true,
				sentry: true,
			}),
			browserTracingIntegration({
				enableInp: true,
				// Requires updating backend to include baggage header/etc
				traceFetch: false,
			}),
			browserProfilingIntegration(),
		],
		tracePropagationTargets: [/^https:\/\/(.*\.)?troon\.digital/],
		tracesSampleRate: getConfigValue('ENVIRONMENT') === 'production' && isEnabled('web-profiling') ? 1.0 : 0,
		profilesSampleRate: getConfigValue('ENVIRONMENT') === 'production' && isEnabled('web-profiling') ? 1.0 : 0,
		release: import.meta.env.VITE_GIT_SHA,
		ignoreErrors: [/transition was (aborted|skipped)/i, /view transition/, 'Attempted to assign to readonly property'],
		beforeBreadcrumb(breadcrumb, hint) {
			if (hint?.event.currentTarget) {
				const name = getAccessibleName(hint.event.target);
				if (name) {
					breadcrumb.message = `"${name}" (${breadcrumb.message})`;
				}
			}
			return breadcrumb;
		},
	});
}

// sidestep posthog logging for these flags because we don't need to track them
function isEnabled(name: string) {
	return !!(window.__FLAGS__ && window.__FLAGS__[name]);
}

async function startup() {
	await polyfill();

	mount(() => {
		return (
			<ErrorBoundary>
				<ApiClient>
					<AnalyticsProvider posthog={posthog}>
						<StartClient />
					</AnalyticsProvider>
				</ApiClient>
			</ErrorBoundary>
		);
	}, document.getElementById('app')!);
}

startup();
